import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import vueCustomElement from 'vue-custom-element'

Vue.use(vueCustomElement)

Vue.config.productionTip = false

App.vuetify = vuetify
App.i18n = i18n

Vue.customElement('recruiting-tool-widget', App)

// new Vue({
//   vuetify,
//   i18n,
//   render: h => h(App)
// }).$mount('#app')