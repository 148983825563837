<template>
    <v-container fluid="fluid" class="transparent">
        <!-- Case: Active Jobs -->
        <v-row v-if="!noActiveJob">
            <v-col class="pl-0 pb-0">
                <v-btn v-if="getURLSearchParams('jobId')" tile text color="secondary" @click="deleteURLSearchParams('jobId')">
                    <v-icon left="left">mdi-chevron-left</v-icon>
                    {{$t('index.backCampaign')}}
                </v-btn>
            </v-col>
        </v-row>
        <v-row v-if="!noActiveJob">
            <!-- Recruiting Form -->
            <v-col cols="12" :md="(jobInterestOption&&jobInterestOption.length===1&&($i18n.locale!=='fr'?jobInterestOption[0].html:jobInterestOption[0].htmlfr))||(jobInterestOption&&jobInterestOption.length!==1&&jobInterestOption[0].generatelinks)?'6':'12'">
                <v-card outlined="outlined" elevation="1">
                    <v-card-text>
                        <v-skeleton-loader v-if="!jobInterestOption" class="mx-auto" type="sentences"></v-skeleton-loader>
                        <div v-if="jobInterestOption" class="overline text-center">
                            {{ title?title:$t('title.cardTextOverline') }}
                        </div>
                    </v-card-text>
                    <v-card-text class="pa-0">
                        <v-form ref="form" v-model="valid" lazy-validation target="_parent">
                            <v-row v-if="((jobInterestOption&&jobInterestOption.length===1)||(jobInterestOption&&!jobInterestOption[0].generatelinks)&&jobInterestOption.length<=5)" class="hidden-xs-only">
                                <v-col class="pa-0 mb-6" cols="12">
                                    <v-bottom-navigation class="d-none elevation-0" v-model="form.jobInterest" color="secondary" grow="grow" height="auto">
                                        <v-btn v-for="item in jobInterestOption" :key="item.value" :value="item.value">
                                            <span>{{ $i18n.locale!=='fr'?item.text:item.textfr }}</span>
                                            <v-icon>{{ item.icon }}</v-icon>
                                        </v-btn>
                                    </v-bottom-navigation>
                                    <div v-if="(jobInterestOption&&jobInterestOption.length===1)">
                                        <p class="secondary--text text-center mb-0 font-weight-medium" v-for="item in jobInterestOption" :key="item.value" :value="item.value">{{ $i18n.locale!=='fr'?item.text:item.textfr }}</p>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <div class="pa-4">
                                <v-slide-x-transition hide-on-leave>
                                    <v-container v-if="view.index">
                                        <v-skeleton-loader v-if="!jobInterestOption" class="mx-auto" type="table-thead,text,article@3,text@2"></v-skeleton-loader>
                                        <v-row v-if="jobInterestOption&&jobInterestOption.length>1">
                                            <v-col cols="12" class="pa-3">
                                                <v-select dense="dense" outlined="outlined" attach="attach" v-model="form.jobInterest" v-bind:rules="formRules.jobInterestRules" v-bind:items="jobInterestOption" v-bind:item-text="item=>$i18n.locale!=='fr'?item.text:item.textfr" v-bind:prepend-icon="jobInterestOptionIcon()" :label="$t('index.jobInterestInputLabel')" v-bind:disabled="loading" required="required"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="jobInterestOption">
                                            <v-col cols="12" sm="6" class="pa-3">
                                                <v-text-field dense="dense" outlined="outlined" v-model="form.firstName" v-bind:rules="formRules.firstNameRules" prepend-icon="mdi-form-textbox" :label="$t('index.firstNameInputLabel')" v-bind:disabled="loading" required="required"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" class="pa-3">
                                                <v-text-field dense="dense" outlined="outlined" v-model="form.lastName" v-bind:rules="formRules.lastNameRules" prepend-icon="mdi-form-textbox" :label="$t('index.lastNameInputLabel')" v-bind:disabled="loading" required="required"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="jobInterestOption">
                                            <v-col cols="12" sm="6" class="pa-3">
                                                <v-text-field dense="dense" outlined="outlined" v-model="form.email" type="email" v-bind:rules="formRules.emailRules" prepend-icon="mdi-email-outline" :label="$t('index.emailInputLabel')" v-bind:disabled="loading" required="required"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" class="pa-3">
                                                <v-text-field dense="dense" outlined="outlined" v-model="form.phone" type="tel" v-bind:rules="formRules.phoneRules" prepend-icon="mdi-phone-outline" :label="$t('index.phoneInputLabel')" v-bind:disabled="loading" required="required"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="jobInterestOption">
                                            <v-col cols="12" sm="6" class="pa-3">
                                                <v-text-field dense="dense" outlined="outlined" v-model="form.city" v-bind:rules="formRules.cityRules" prepend-icon="mdi-city-variant-outline" :label="$t('index.cityInputLabel')" v-bind:disabled="loading" required="required"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" class="pa-3">
                                                <v-autocomplete dense="dense" outlined="outlined" v-model="form.state" v-bind:items="stateOption" item-text="text" item-value="value" hide-no-data clearable hide-selected menu-props="auto, overflowY" attach="attach" v-bind:rules="formRules.stateRules" prepend-icon="mdi-home-group" :label="$t('index.stateInputLabel')" v-bind:disabled="loading" required="required"></v-autocomplete>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="jobInterestOption&&jobInterestQuestionText.length">
                                            <v-col cols="12" class="pa-3">
                                                <v-text-field dense="dense" outlined="outlined" v-model="form.textItems[index]" v-bind:rules="formRules.genericTextFieldRules" v-for="(item, index) in jobInterestQuestionText" :key="item.questionTitle" :value="index"
                                                    :prepend-icon="item.icon?item.icon:'mdi-dots-horizontal-circle-outline'" :label="$i18n.locale!=='fr'?item.questionTitle:item.questionTitleFR" v-bind:disabled="loading"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="jobInterestOption&&jobInterestQuestionTextArea.length">
                                            <v-col cols="12" class="pa-3">
                                                <v-textarea dense="dense" outlined="outlined" auto-grow="auto-grow" rows="1" v-model="form.textAreaItems[index]" v-bind:rules="formRules.genericTextFieldRules" v-for="(item, index) in jobInterestQuestionTextArea" :key="item.questionTitle" :value="index"
                                                    :prepend-icon="item.icon?item.icon:'mdi-dots-horizontal-circle-outline'" :label="$i18n.locale!=='fr'?item.questionTitle:item.questionTitleFR" v-bind:disabled="loading"></v-textarea>
                                            </v-col>
                                        </v-row>
                                        <v-row v-for="(item, index) in jobInterestQuestionMultiPicklist" :key="item.questionTitle" :value="item.questionTitle">
                                            <v-col cols="12" class="pa-3">
                                                <v-combobox dense="dense" outlined="outlined" attach="attach" small-chips="small-chips" clearable="clearable" multiple="multiple" menu-props="auto, overflowY" v-model="form.multiPicklistItems[index]"
                                                    :items="$i18n.locale!=='fr'?item.multipleChoice.split(','):item.multipleChoiceFR.split(',')" item-value="multipleChoice"
                                                    :prepend-icon="item.icon?item.icon:'mdi-dots-horizontal-circle-outline'" :label="$i18n.locale!=='fr'?item.questionTitle:item.questionTitleFR" v-bind:disabled="loading">
                                                    <template v-slot:selection="data">
                                                        <v-chip small="small" :key="JSON.stringify(data.item)" v-bind="data.attrs" :input-value="data.selected" :disabled="data.disabled" @click:close="data.parent.selectItem(data.item)">
                                                            <v-avatar left="left"><v-icon class="secondary" dark="dark" small="small">mdi-check</v-icon></v-avatar>
                                                            {{ data.item }}
                                                        </v-chip>
                                                    </template>
                                                </v-combobox>
                                            </v-col>
                                        </v-row>
                                        <v-row v-for="(item, index) in jobInterestQuestionPicklist" :key="item.questionTitle" :value="item.questionTitle">
                                            <v-col cols="12" class="pa-3">
                                                <v-select dense="dense" outlined="outlined" attach="attach" menu-props="auto, overflowY" v-model="form.picklistItems[index]"
                                                    :items="$i18n.locale!=='fr'?item.multipleChoice.split(','):item.multipleChoiceFR.split(',')" item-value="multipleChoice"
                                                    :prepend-icon="item.icon?item.icon:'mdi-dots-horizontal-circle-outline'" :label="$i18n.locale!=='fr'?item.questionTitle:item.questionTitleFR" v-bind:disabled="loading"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="jobInterestOption&&jobInterestQuestionCheckbox.length">
                                            <v-col class="mb-4">
                                                <v-checkbox hide-details :class="index===0?'mt-0':''" v-for="(item, index) in jobInterestQuestionCheckbox" :key="item.questionTitle"
                                                    v-model="form.checkboxItems[index]" :label="$i18n.locale!=='fr'?item.questionTitle:item.questionTitleFR" v-bind:disabled="loading"></v-checkbox>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="jobInterestOption">
                                            <v-col cols="12" class="pa-3">
                                                <v-file-input dense="dense" outlined="outlined" chips="chips" small-chips="small-chips" show-size="show-size" accept="application/pdf" v-model="form.fileUpload" v-bind:rules="formRules.resumeRules" :label="$t('index.attachInputLabel')" :hint="$t('index.attachInputHint')" persistent-hint="persistent-hint" v-bind:disabled="loading"></v-file-input>
                                            </v-col>
                                        </v-row>
                                        <v-checkbox v-model="form.bySubmittingMyInformation" :label="$t('index.bySubmittingMyInformationLabel')" v-bind:disabled="loading"></v-checkbox>
                                    </v-container>
                                </v-slide-x-transition>
                                <v-slide-x-transition hide-on-leave>
                                    <v-container v-if="view.thankYou">
                                        <v-row>
                                            <v-col class="text-center">
                                                <v-icon size="60" color="primary">mdi-send-check-outline</v-icon>
                                                <h2 class="display-1">
                                                    {{ $t('thankYou.header1') }}
                                                </h2>
                                                <h3 class="headline">
                                                    {{ $t('thankYou.header2') }}
                                                </h3>
                                                <p class="body-1">
                                                    {{ $t('thankYou.body1') }}
                                                </p>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-slide-x-transition>
                            </div>
                        </v-form>
                    </v-card-text>
                    <v-divider v-if="view.index"></v-divider>
                    <v-card-actions v-if="view.index">
                        <v-container class="pa-0">
                            <v-skeleton-loader v-if="!jobInterestOption" class="mx-auto" type="actions,sentences"></v-skeleton-loader>
                            <v-row v-if="jobInterestOption">
                                <v-col>
                                    <v-btn color="secondary" block="block" v-ripple="loading?false:true" v-on:click="loading?null:validate()">
                                        {{loading?$t('index.loading'):$t('index.submit')}}
                                        <v-icon v-if="!loading" right="right">mdi-send-outline</v-icon>
                                        <v-progress-circular v-if="loading" class="ml-4" indeterminate="indeterminate" size="23"></v-progress-circular>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row v-if="jobInterestOption" class="mt-0">
                                <v-col class="text-center">
                                    <span class="text-caption text--disabled"><v-icon class="text--disabled" left="left" dense="dense">mdi-lock-outline</v-icon>{{ $t('index.reCAPTCHA') }}</span>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-actions>
                </v-card>
            </v-col>
            <!-- Job List -->
            <v-col class="text--primary" v-if="jobInterestOption&&jobInterestOption.length!==1&&jobInterestOption[0].generatelinks" cols="12" md="6">
                <div class="d-flex mb-7">
                    <hr class="shortv mr-2" />
                    <div>
                        <h2 class="v-heading text-h4 text-sm-h4 mb-1 font-weight-medium">{{ $t('title.paraTextTitle') }}</h2>
                        <p class="mb-0 font-weight-light">{{ $t('title.paraTextSubTitle') }}</p>
                    </div>
                </div>
                <v-list dense nav flat class="pa-0">
                    <v-list-item-group class="font-weight-light" v-model="listJobCampaign">
                        <v-hover class="job-list-container" v-slot="{ hover }" v-for="item in jobInterestOption" :key="item.value">
                            <div :class="{'ml-3':hover}">
                                <v-list-item class="accent px-3 py-0 my-1 rounded-sm" ripple :value="item.value" :href="jobIdURLSearchParams(item.value)">
                                    <v-list-item-icon class="py-0 mr-n2">
                                        <hr class="shortv"/>
                                    </v-list-item-icon>
                                    <v-list-item-content class="py-0">
                                        <v-list-item-title v-text="$i18n.locale!=='fr'?item.text:item.textfr"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </div>
                        </v-hover>
                    </v-list-item-group>
                </v-list>
            </v-col>
            <!-- Job Description -->
            <v-col class="text--primary" v-if="jobInterestOption&&jobInterestOption.length==1&&($i18n.locale!=='fr'?jobInterestOption[0].html:jobInterestOption[0].htmlfr)" cols="12" md="6">
                <!-- Short Description -->
                <div class="ml-2">
                    <div class="d-flex mb-7">
                        <hr class="shortv mr-2" />
                        <div>
                            <h2 class="v-heading text-h4 text-sm-h4 mb-1 font-weight-medium" v-html="$i18n.locale!=='fr'?jobInterestOption[0].text:jobInterestOption[0].textfr"></h2>
                            <p class="mb-1 font-weight-light" v-if="jobInterestOption&&jobInterestOption.length===1&&jobInterestOption[0].location"><v-icon left="left" color="primary">mdi-map-marker</v-icon>{{jobInterestOption[0].location}}</p>
                        </div>
                    </div>
                    <p class="font-weight-light" v-html="$i18n.locale!=='fr'?jobInterestOption[0].html:jobInterestOption[0].htmlfr"></p>
                </div>
                <!-- Long Description -->
                <v-row v-if="jobInterestOption&&jobInterestOption.length===1&&($i18n.locale!=='fr'?jobInterestOption[0].longhtml:jobInterestOption[0].longhtmlfr)" justify="center">
                    <v-dialog v-model="dynamicDialog" scrollable attach="attach" max-width="644">
                        <template v-slot:activator="{ on, attrs }">
                            <v-col class="d-flex">
                                <v-btn color="primary" v-bind="attrs" v-on="on">{{ $t('dialog.btn') }}</v-btn>
                            </v-col>
                        </template>
                        <v-card>
                            <v-card-title class="text-h5 primary">
                                <span class="text-h5 white--text">{{ $t('dialog.header1') }}</span>
                            </v-card-title>
                            <v-card-text class="pt-5 text--primary">
                                <h2 class="v-heading text-h4 text-sm-h4 mb-1 font-weight-medium" v-html="$i18n.locale!=='fr'?jobInterestOption[0].text:jobInterestOption[0].textfr"></h2>
                                <p class="mb-7 font-weight-light" v-if="jobInterestOption&&jobInterestOption.length===1&&jobInterestOption[0].location"><v-icon left="left" color="primary">mdi-map-marker</v-icon>{{jobInterestOption[0].location}}</p>
                                <p class="font-weight-light" v-html="jobInterestOption&&jobInterestOption.length===1&&($i18n.locale!=='fr'?jobInterestOption[0].longhtml:jobInterestOption[0].longhtmlfr)"></p>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" width="25%" @click="dynamicDialog = false">
                                    <v-icon left="left">mdi-close-circle-outline</v-icon>
                                    {{ $t('snackbar.close') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </v-col>
        </v-row>
        <!-- Case: No Active Jobs -->
        <v-card v-if="noActiveJob">
            <v-card-text class="text-center">
                <p class="text-h4 text--primary">
                    {{ $t('noActiveJob.header1') }}
                </p>
                <p>{{ $t('noActiveJob.body1') }}</p>
                <div class="text--primary">
                    {{ $t('noActiveJob.body2') }}
                </div>
            </v-card-text>
        </v-card>
        <p v-if="phone" class="text-h6 mt-8 mb-0 font-weight-regular text-center">{{ $t('phone.body1') }}</p>
        <v-card v-if="phone" class="mx-auto my-2" color="secondary" dark max-width="300">
            <v-card-text>
                <v-row align="center">
                    <v-col cols="3">
                        <v-icon x-large>mdi-phone</v-icon>
                    </v-col>
                    <v-col class="text-h2" cols="9">
                        <v-card-title class="pb-0 pt-0 pl-0">
                            <span class="text-h5 font-weight-light">{{ $t('phone.cardTextHeader') }}</span>
                        </v-card-title>
                        <v-card-text class="text-h5 text-left font-weight-bold pb-0 pl-0">
                            <a class="text-decoration-none white--text" :href="'tel:'+phone" v-on:click="gTagSendEvents('Phone','','',0)">{{ phone }}</a>
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-snackbar v-model="snackbar" timeout="2000">
            {{ $t('snackbar.body1') }}
            <template v-slot:action="{ attrs }">
                <v-btn color="secondary" text v-bind="attrs" @click="snackbar=false">
                    {{ $t('snackbar.close') }}
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>
<script>
import axios from 'axios';
import { load } from 'recaptcha-v3';
// import gtag, { install } from 'ga-gtag';
// import {init, track} from 'fbq';
// install(process.env.VUE_APP_TRACKING);
// init(process.env.VUE_APP_FBID);
  export default {
    name: 'HelloWorld',
    props: {
        lang: String,
        phone: String,
        job: String,
        campaign: String,
        title: String,
        primary: String,
        secondary: String,
    },
    data: () => ({
      valid: true,
      loading: false,
      dynamicDialog: false,
      snackbar: false,
      noActiveJob: false,
      source: window.location.href,
      view: {
          index: true,
          thankYou: false,
      },
      form: {
          jobInterest: '',
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          city: '',
          state: '',
          checkboxItems: [],
          multiPicklistItems: [],
          picklistItems: [],
          textItems: [],
          textAreaItems: [],
          fileUpload: null,
          bySubmittingMyInformation: false,
      },
      jobInterestOption: null,
      jobInterestQuestionCheckbox: [],
      jobInterestQuestionMultiPicklist: [],
      jobInterestQuestionPicklist: [],
      jobInterestQuestionText: [],
      jobInterestQuestionTextArea: [],
      listJobCampaign: [],
    }),
    async created(){
        var vm = this;
        await axios.post(process.env.VUE_APP_RESTURL, {
        jobList: this.dataReturnJob,
        method: "returnJob",
        })
        .then(function (response) {
            if(response.status===200&&response.data) {
                var data = JSON.parse(response.data);
                if(data.length>0) {
                    vm.jobInterestOption = data;
                }else{
                    vm.noActiveJob = true;
                }
            }else{
                vm.noActiveJob = true;
            }
        })
        .catch(function (error) {
            vm.noActiveJob = true;
            console.log(error);
        });
    },
    mounted: function(){
        this.$i18n.locale = this.lang;
        if(this.primary){
            this.$vuetify.theme.themes.light.primary = this.primary;
        }
        if(this.secondary){
            this.$vuetify.theme.themes.light.secondary = this.secondary;
        }
        this.gTagSendEvents('Load Form','Recruiting Tool',window.location.href,0);
        this.campaignStorageTracking();
    },
    computed: {
        stateOption() {
            return [
                // {header: this.$i18n.t('provinceNames.UnitedStatesofAmerica'),},
                // {text: this.$i18n.t('provinceNames.Alabama'),value: 'AL',},
                // {text: this.$i18n.t('provinceNames.Alaska'),value: 'AK',},
                // {text: this.$i18n.t('provinceNames.Arizona'),value: 'AZ',},
                // {text: this.$i18n.t('provinceNames.Arkansas'),value: 'AR',},
                // {text: this.$i18n.t('provinceNames.California'),value: 'CA',},
                // {text: this.$i18n.t('provinceNames.Colorado'),value: 'CO',},
                // {text: this.$i18n.t('provinceNames.Connecticut'),value: 'CT',},
                // {text: this.$i18n.t('provinceNames.DistColumbia'),value: 'DC',},
                // {text: this.$i18n.t('provinceNames.Delaware'),value: 'DE',},
                // {text: this.$i18n.t('provinceNames.Florida'),value: 'FL',},
                // {text: this.$i18n.t('provinceNames.Georgia'),value: 'GA',},
                // {text: this.$i18n.t('provinceNames.Hawaii'),value: 'HI',},
                // {text: this.$i18n.t('provinceNames.Idaho'),value: 'ID',},
                // {text: this.$i18n.t('provinceNames.Illinois'),value: 'IL',},
                // {text: this.$i18n.t('provinceNames.Indiana'),value: 'IN',},
                // {text: this.$i18n.t('provinceNames.Iowa'),value: 'IA',},
                // {text: this.$i18n.t('provinceNames.Kansas'),value: 'KS',},
                // {text: this.$i18n.t('provinceNames.Kentucky'),value: 'KY',},
                // {text: this.$i18n.t('provinceNames.Louisiana'),value: 'LA',},
                // {text: this.$i18n.t('provinceNames.Maine'),value: 'ME',},
                // {text: this.$i18n.t('provinceNames.Maryland'),value: 'MD',},
                // {text: this.$i18n.t('provinceNames.Massachusetts'),value: 'MA',},
                // {text: this.$i18n.t('provinceNames.Michigan'),value: 'MI',},
                // {text: this.$i18n.t('provinceNames.Minnesota'),value: 'MN',},
                // {text: this.$i18n.t('provinceNames.Mississippi'),value: 'MS',},
                // {text: this.$i18n.t('provinceNames.Missouri'),value: 'MO',},
                // {text: this.$i18n.t('provinceNames.Montana'),value: 'MT',},
                // {text: this.$i18n.t('provinceNames.Nebraska'),value: 'NE',},
                // {text: this.$i18n.t('provinceNames.Nevada'),value: 'NV',},
                // {text: this.$i18n.t('provinceNames.NewHampshire'),value: 'NH',},
                // {text: this.$i18n.t('provinceNames.NewJersey'),value: 'NJ',},
                // {text: this.$i18n.t('provinceNames.NewMexico'),value: 'NM',},
                // {text: this.$i18n.t('provinceNames.NewYork'),value: 'NY',},
                // {text: this.$i18n.t('provinceNames.NorthCarolina'),value: 'NC',},
                // {text: this.$i18n.t('provinceNames.NorthDakota'),value: 'ND',},
                // {text: this.$i18n.t('provinceNames.Ohio'),value: 'OH',},
                // {text: this.$i18n.t('provinceNames.Oklahoma'),value: 'OK',},
                // {text: this.$i18n.t('provinceNames.Oregon'),value: 'OR',},
                // {text: this.$i18n.t('provinceNames.Pennsylvania'),value: 'PA',},
                // {text: this.$i18n.t('provinceNames.RhodeIsland'),value: 'RI',},
                // {text: this.$i18n.t('provinceNames.SouthCarolina'),value: 'SC',},
                // {text: this.$i18n.t('provinceNames.SouthDakota'),value: 'SD',},
                // {text: this.$i18n.t('provinceNames.Tennessee'),value: 'TN',},
                // {text: this.$i18n.t('provinceNames.Texas'),value: 'TX',},
                // {text: this.$i18n.t('provinceNames.Utah'),value: 'UT',},
                // {text: this.$i18n.t('provinceNames.Vermont'),value: 'VT',},
                // {text: this.$i18n.t('provinceNames.Virginia'),value: 'VA',},
                // {text: this.$i18n.t('provinceNames.Washington'),value: 'WA',},
                // {text: this.$i18n.t('provinceNames.WestVirginia'),value: 'WV',},
                // {text: this.$i18n.t('provinceNames.Wisconsin'),value: 'WI',},
                // {text: this.$i18n.t('provinceNames.Wyoming'),value: 'WY',},
                // {divider: true,},
                // {header: this.$i18n.t('provinceNames.Canada'),},
                {text: this.$i18n.t('provinceNames.Alberta'),value: 'Alberta',},
                {text: this.$i18n.t('provinceNames.BritishColumbia'),value: 'British Columbia',},
                {text: this.$i18n.t('provinceNames.Manitoba'),value: 'Manitoba',},
                {text: this.$i18n.t('provinceNames.NewBrunswick'),value: 'New Brunswick',},
                {text: this.$i18n.t('provinceNames.NewfoundlandLabrador'),value: 'Newfoundland and Labrador',},
                {text: this.$i18n.t('provinceNames.NovaScotia'),value: 'Nova Scotia',},
                {text: this.$i18n.t('provinceNames.Nunavut'),value: 'Nunavut',},
                {text: this.$i18n.t('provinceNames.NorthWestTerr'),value: 'North West Terr.',},
                {text: this.$i18n.t('provinceNames.Ontario'),value: 'Ontario',},
                {text: this.$i18n.t('provinceNames.PrinceEdwardIs'),value: 'Prince Edward Is.',},
                {text: this.$i18n.t('provinceNames.Quebec'),value: 'Quebec',},
                {text: this.$i18n.t('provinceNames.Saskatchewen'),value: 'Saskatchewen',},
                {text: this.$i18n.t('provinceNames.Yukon'),value: 'Yukon',},
                {text: this.$i18n.t('provinceNames.Other'),value: 'Other',},
            ];
        },
        formRules() {
            return {
                jobInterestRules: [
                    v => !!v || this.$i18n.t('formRules.jobInterestRules'),
                ],
                firstNameRules: [
                    v => !!v || this.$i18n.t('formRules.firstNameRules'),
                    v => v && v.length >= 2 && v.length <= 100 || this.$i18n.t('formRules.firstNameRulesLength'),
                    v => /^([A-Za-zàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]{1})+([[A-Za-zàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+[,.]?[ ]?|[A-Za-z]+['-àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]]?)+$/.test(v) || this.$i18n.t('formRules.firstNameRulesPattern'),
                ],
                lastNameRules: [
                    v => !!v || this.$i18n.t('formRules.lastNameRules'),
                    v => v && v.length >= 2 && v.length <= 100 || this.$i18n.t('formRules.lastNameRulesLength'),
                    v => /^([A-Za-zàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]{1})+([[A-Za-zàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+[,.]?[ ]?|[A-Za-z]+['-àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]]?)+$/.test(v) || this.$i18n.t('formRules.lastNameRulesPattern'),
                ],
                emailRules: [
                    v => !!v || this.$i18n.t('formRules.emailRules'),
                    v => /.+@.+\..+/.test(v) || this.$i18n.t('formRules.emailRulesPattern'),
                ],
                phoneRules: [
                    v => !!v || this.$i18n.t('formRules.phoneRules'),
                    v => v && v.length >= 2 && v.length <= 100 || this.$i18n.t('formRules.phoneRulesLength'),
                    v => /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(v) || this.$i18n.t('formRules.phoneRulesPattern'),
                ],
                cityRules: [
                    v => !!v || this.$i18n.t('formRules.cityRules'),
                    v => !!v && v.length >= 2 && v.length <= 100 || this.$i18n.t('formRules.cityRulesLength'),
                ],
                stateRules: [
                    v => !!v || this.$i18n.t('formRules.stateRules'),
                ],
                genericTextFieldRules: [
                    v => !v || v.length <= 255 || this.$i18n.t('formRules.genericTextFieldRules'),
                ],
                resumeRules: [
                    v => !v || v.size < 4000000 || this.$i18n.t('formRules.resumeRulesLength'),
                ],
            };
        },
        dataReturnJob() {
            let data = "";
            const urlParams = new URLSearchParams(window.location.search);
            const jobid = urlParams.get("jobId")?urlParams.get("jobId").replace('/',''):null;
            const campaignId = urlParams.get("campaignId")?urlParams.get("campaignId").replace('/',''):null;
            if(jobid){
                return data = jobid;
            }
            if(campaignId){
                return data = campaignId;
            }
            if(this.job){
                return data = this.job;
            }
            if(this.campaign){
                return data = this.campaign;
            }
            return data;
        }
    },
    watch: {
        'form.jobInterest': function () {
            var vm = this;
            var findJobInterest = this.jobInterestOption.findIndex(function callbackFn(o) { return o.value == vm.form.jobInterest; });
            if(findJobInterest !== -1 && vm.jobInterestOption[findJobInterest].questions){
               var data = vm.jobInterestOption[findJobInterest].questions;
               if(data.filter(o => o.questionContents === 'Checkbox').length>0){
                    vm.jobInterestQuestionCheckbox = data.filter(o => o.questionContents === 'Checkbox');
               } else {
                   vm.jobInterestQuestionCheckbox = [];
               }
               if(data.filter(o => o.questionContents === 'Multi Picklist').length>0){
                    vm.jobInterestQuestionMultiPicklist = data.filter(o => o.questionContents === 'Multi Picklist');
               }else{
                   vm.jobInterestQuestionMultiPicklist = [];
               }
               if(data.filter(o => o.questionContents === 'Picklist').length>0){
                    vm.jobInterestQuestionPicklist = data.filter(o => o.questionContents === 'Picklist');
               }else{
                   vm.jobInterestQuestionPicklist = [];
               }
               if(data.filter(o => o.questionContents === 'Text').length>0){
                    vm.jobInterestQuestionText = data.filter(o => o.questionContents === 'Text');
               }else{
                   vm.jobInterestQuestionText = [];
               }
               if(data.filter(o => o.questionContents === 'Text Area').length>0){
                    vm.jobInterestQuestionTextArea = data.filter(o => o.questionContents === 'Text Area');
               }else{
                   vm.jobInterestQuestionTextArea = [];
               }
            }else{
                vm.jobInterestQuestionCheckbox = [];
                vm.jobInterestQuestionMultiPicklist = [];
                vm.jobInterestQuestionPicklist = [];
                vm.jobInterestQuestionText = [];
                vm.jobInterestQuestionTextArea = [];
            }
        }
    },
    methods: {
        campaignStorageTracking(){
            const urlParams = new URLSearchParams(window.parent.location.search);
            let campaignStorage = {};
            let utmSource = "", utmCampaign = "", utmMedium = "", utmTerm = "", gclid = "", fclid = "", referrer = "";
            const campaignURL = JSON.parse(sessionStorage.getItem("campaignURL"));
            if(campaignURL){
                utmSource = campaignURL["utmSource"];
                utmCampaign = campaignURL["utmCampaign"];
                utmMedium = campaignURL["utmMedium"];
                utmTerm = campaignURL["utmTerm"];
                gclid = campaignURL["gclid"];
                fclid = campaignURL["fclid"];
                referrer = campaignURL["referrer"];
            }
            campaignStorage["utmSource"] = urlParams.get("utm_source")?encodeURIComponent(urlParams.get("utm_source")):utmSource?utmSource:"";
            campaignStorage["utmCampaign"] = urlParams.get("utm_campaign")?encodeURIComponent(urlParams.get("utm_campaign")):utmCampaign?utmCampaign:"";
            campaignStorage["utmMedium"] = urlParams.get("utm_medium")?encodeURIComponent(urlParams.get("utm_medium")):utmMedium?utmMedium:"";
            campaignStorage["utmTerm"] = urlParams.get("utm_term")?encodeURIComponent(urlParams.get("utm_term")):utmTerm?utmTerm:"";
            campaignStorage["gclid"] = urlParams.get("gclid")?encodeURIComponent(urlParams.get("gclid")):gclid?gclid:"";
            campaignStorage["fclid"] = urlParams.get("fclid")?encodeURIComponent(urlParams.get("fclid")):fclid?fclid:"";
            campaignStorage["referrer"] = urlParams.get("referrer")?encodeURIComponent(urlParams.get("referrer")):referrer?referrer:"";
            if(campaignStorage["utmSource"]||campaignStorage["utmCampaign"]||campaignStorage["utmMedium"]||campaignStorage["utmTerm"]||campaignStorage["gclid"]||campaignStorage["fclid"]||campaignStorage["referrer"]){
                sessionStorage.setItem("campaignURL", JSON.stringify(campaignStorage));
            }
        },
        async convertFileUpload(file){
            if (file) {
                const reader = new FileReader();
                return new Promise((resolve) => {
                    reader.addEventListener('load', () => {
                        resolve(window.result = reader.result.split(',').pop());
                    }, false);
                    reader.readAsDataURL(file);
                });
            }
        },
        jobInterestOptionIcon() {
            // var vm = this;
            // var findJobInterest = this.jobInterestOption.findIndex(function callbackFn(o) { return o.value == vm.form.jobInterest; });
            // if(findJobInterest !== -1){
            //     return this.jobInterestOption[findJobInterest].icon;
            // }
            return 'mdi-progress-question';
        },
        jobInterestOptionText() {
            var vm = this;
            var findJobInterest = this.jobInterestOption.findIndex(function callbackFn(o) { return o.value == vm.form.jobInterest; });
            if(findJobInterest !== -1){
                return this.jobInterestOption[findJobInterest].text;
            }
            return '';
        },
        gTagSendEvents(action, category, label, value) {
            if (window.google_tag_manager && typeof window.dataLayer !== "undefined" && typeof action !== "undefined" && typeof category !== "undefined" && typeof label !== "undefined" && typeof value !== "undefined") {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                event: 'eventTracking',
                action: action,
                event_category: category,
                event_label: label,
                value: value,
                });
            }
        },
        fbqSendEvents(type) {
            if (typeof window.fbq !== "undefined") {
                type==='location'?window.fbq('track', 'FindLocation'):window.fbq('track', 'SubmitApplication');
            }
        },
        getURLSearchParams(urlPart) {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get(urlPart);
        },
        jobIdURLSearchParams(item) {
            const urlParams = new URLSearchParams(window.location.search);
            if(urlParams.toString()){
                return '?jobId=' + item +'&'+ urlParams.toString();
            }else {
                return '?jobId=' + item;
            }
        },
        deleteURLSearchParams(urlPart) {
            const urlParams = new URLSearchParams(window.location.search);
            if(urlParams.has(urlPart)){
                urlParams.delete(urlPart);
                if(urlParams.toString()){
                    window.location.href = window.location.pathname +'?'+ urlParams.toString();
                }else {
                    window.location.href = window.location.pathname;
                }
            }
        },
        validate() {
            var vm = this;
            if(vm.$refs.form.validate()){
                this.loading = true;
                load(process.env.VUE_APP_reCAPTCHA).then((recaptcha) => {
                    recaptcha.execute('recruitingTool').then(function (token) {
                        axios.post(process.env.VUE_APP_RESTURL, {
                        method: "validation",
                        token: token
                        })
                        .then(function (response) {
                            if(response.status===200) {
                                var data = JSON.parse(response.data);
                                vm.gTagSendEvents('Validate Form','Recruiting Tool',vm.form.jobInterest,0);
                                vm.fbqSendEvents('');
                                if(data.success&&data.action==='recruitingTool'&&data.score>=0.5){
                                    vm.sendJobInterestInfo();
                                }else{
                                    vm.loading = false;
                                }
                            }else{
                                vm.loading = false;
                                vm.snackbar = true;
                            }
                        })
                        .catch(function (error) {
                            vm.loading = false;
                            vm.snackbar = true;
                            console.log(error);
                        });
                    });
                })
            }
        },
        async sendJobInterestInfo() {
            this.loading = true;
            var vm = this;
            let utmSource = '', utmCampaign = '', utmMedium = '', utmTerm = '', gclid = '', fclid = '', referrer = '';
            const campaignURL = JSON.parse(sessionStorage.getItem("campaignURL"));
            if(campaignURL){
                utmSource = campaignURL["utmSource"];
                utmCampaign = campaignURL["utmCampaign"];
                utmMedium = campaignURL["utmMedium"];
                utmTerm = campaignURL["utmTerm"];
                gclid = campaignURL["gclid"];
                fclid = campaignURL["fclid"];
                referrer = campaignURL["referrer"];
            }
            const file = await this.convertFileUpload(this.form.fileUpload);
            axios.post(process.env.VUE_APP_RESTURL, {
                jobInterest: this.form.jobInterest,
                jobInterestName: this.jobInterestOptionText(),
                firstName: this.form.firstName,
                lastName: this.form.lastName,
                email: this.form.email,
                phone: this.form.phone,
                city: this.form.city,
                state: this.form.state,
                questions: this.jobInterestQuestionToObject(),
                fileUpload: file,
                fileUploadType: this.form.fileUpload?this.form.fileUpload.type:'',
                fileUploadName: this.form.fileUpload?this.form.fileUpload.name:'',
                consentForSMSEmail: this.form.bySubmittingMyInformation,
                utmSource: utmSource,
                utmCampaign: utmCampaign,
                utmMedium: utmMedium,
                utmTerm: utmTerm,
                gClId: gclid,
                lang: this.lang,
                source: this.source,
                method: 'sendLead'
            }).then(function (response) {
                if(response.status === 200) {
                    var res = JSON.parse(response.data);
                    if(res){
                        vm.viewForm('thankYou');
                    }else{
                        vm.loading = false;
                        vm.snackbar = true;
                    }
                } else {
                    vm.loading = false;
                    vm.snackbar = true;
                }
            }).catch(function (error) {
                vm.loading = false;
                vm.snackbar = true;
                console.log(error);
            });
        },
        jobInterestQuestionToObject(){
            const questionsObject = [];
            Object.entries(this.jobInterestQuestionCheckbox).forEach(entry => {
                const [key, value] = entry;
                if(this.form.checkboxItems[key]){
                    questionsObject.push({ExternalId: value.externalID, checkboxValue: this.form.checkboxItems[key]});
                }else{
                    questionsObject.push({ExternalId: value.externalID, checkboxValue: false});
                }
            });
            Object.entries(this.jobInterestQuestionMultiPicklist).forEach(entry => {
                const [key, value] = entry;
                if(this.form.multiPicklistItems[key]){
                    questionsObject.push({ExternalId: value.externalID, textValue: this.form.multiPicklistItems[key].join(", ")});
                }else{
                    questionsObject.push({ExternalId: value.externalID, textValue: ''});
                }
            });
            Object.entries(this.jobInterestQuestionPicklist).forEach(entry => {
                const [key, value] = entry;
                if(this.form.picklistItems[key]){
                    questionsObject.push({ExternalId: value.externalID, textValue: this.form.picklistItems[key]});
                }else{
                    questionsObject.push({ExternalId: value.externalID, textValue: ''});
                }
            });
            Object.entries(this.jobInterestQuestionText).forEach(entry => {
                const [key, value] = entry;
                if(this.form.textItems[key]){
                    questionsObject.push({ExternalId: value.externalID, textValue: this.form.textItems[key]});
                }else{
                    questionsObject.push({ExternalId: value.externalID, textValue: ''});
                }
            });
            Object.entries(this.jobInterestQuestionTextArea).forEach(entry => {
                const [key, value] = entry;
                if(this.form.textAreaItems[key]){
                    questionsObject.push({ExternalId: value.externalID, textValue: this.form.textAreaItems[key]});
                }else{
                    questionsObject.push({ExternalId: value.externalID, textValue: ''});
                }
            });
            return questionsObject.length!==0?questionsObject:null;
        },
        resetForm() {
            this.view.index = true;
            this.view.thankYou = false;
            this.loading = false;
        },
        viewForm(viewFormSelected) {
            this.view.index = false;
            this.view.thankYou = false;
            this.loading = false;
            if(viewFormSelected === 'index'){
                this.view.index = true;
            }else if(viewFormSelected === 'thankYou'){
                this.gTagSendEvents('Submit Application','Recruiting Tool','',0);
                this.view.thankYou = true;
            }
        },
    },
  }
</script>