<template>
  <div data-vuetify>
    <v-app class="rounded transparent">
      <v-main>
        <HelloWorld :lang="lang||'en'" :phone="phone||''" :job="job||''" :campaign="campaign||''" :title="title||''" :primary="primary||''" :secondary="secondary||''"/>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld';

export default {
  name: 'App',
  props: {
    lang: String,
    phone: String,
    job: String,
    campaign: String,
    title: String,
    primary: String,
    secondary: String,
  },
  components: {
    HelloWorld,
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap');
@import url('https://cdn.jsdelivr.net/npm/@mdi/font@6.x/css/materialdesignicons.min.css');
[data-vuetify]{overflow-y:hidden!important}
#app{font-family:Roboto, Helvetica, Arial, sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}
.grecaptcha-badge{visibility:hidden}
.pac-item{font-family:Roboto,sans-serif !important;font-weight:400 !important;color:rgba(0,0,0,.87) !important;line-height:40px !important;font-size:16px;cursor:pointer}
.pac-item-query{font-family:Roboto,sans-serif !important;font-size:16px}
.pac-item:hover{background-color:rgb(246,246,246) !important;transition: .3s cubic-bezier(.25,.8,.5,1)}
.pac-container{color:rgba(0,0,0,.87)!important;max-height:166px;overflow-y:auto}
.pac-icon,.pac-icon-marker{width:0 !important;height:0 !important;background:none !important;background-image:none !important}
.transparent{background-color:transparent !important;border-color:transparent !important}
.row>*{margin-left:initial!important}
div>.rounded{border-radius:4px!important}
div.container--fluid{padding:1px!important}
div.v-application--wrap{min-height:100%!important}
/* This Widget */
.v-item-group.v-bottom-navigation .v-btn{background-color:transparent!important;height:inherit!important;min-width:80px!important}
.v-bottom-navigation>.v-btn>.v-btn__content{white-space:normal;flex:auto}
hr.shortv{ border:1px solid #036349;border-radius:1.5px;height:auto}
.job-list-container{transition:margin 0.3s;}
[data-vuetify] strong{font-weight:bold!important}
</style>
